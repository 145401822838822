<template>
  <div class="mainform">
    <div class="mainHeader">质量异常通知单<span @click="back" class="back">返回></span></div>
    <div v-if="type==='1'?true:false">
      <div class="form">
          <div class="header" >基础信息</div>
          <el-form   class="content bascform" :model="form" :class="{disabled:formDisabled}" :disabled="formDisabled"  label-width="120px">
            <div  class="col col4">
              <el-form-item label="通知单号" >
                  <el-input v-model="form.qualityCode"></el-input>
              </el-form-item>
            </div>
            <div  class="col col4">
              <el-form-item label="公司名称" >
                <span class="onlyText">
                  {{form.companyName}}
                </span>
              </el-form-item>
            </div>
            <div  class="col col4">
              <el-form-item label="单据类型" >
                <el-select v-model="form.type" >
                  <el-option label="来料不合格" :value="1"></el-option>
                  <el-option label="生产质量异常" :value="2"></el-option>
                  </el-select>
              </el-form-item>
            </div>
            <div  class="col col4">
              <el-form-item label="创建日期">
                <span class="onlyText">
                  {{form.createDate}}
                </span>
              </el-form-item>
            </div>
            <div  class="col col4">
              <el-form-item label="备注" >
                  <el-input v-model="form.bz"></el-input>
              </el-form-item>
            </div>
            <div  class="col col4">
              <el-form-item label="状态" >
                  <el-select v-model="form.status" >
                    <el-option label="待采购确认" :value="1"></el-option>
                    <el-option label="待供应商确认" :value="2"></el-option>
                    <el-option label="完成" :value="3"></el-option>
                  </el-select>
              </el-form-item>
            </div>
          </el-form>
          <div class="header">质量对象</div>
          <el-form  class="content bascform" :model="form" :class="{disabled:formDisabled}" :disabled="formDisabled"  label-width="120px">
            <div  class="col col4">
              <el-form-item label="供应商编号" >
                  <el-input v-model="form.supplierCode"></el-input>
              </el-form-item>
            </div>
            <div  class="col col4">
              <el-form-item label="供应商名称" >
                <span class="onlyText">
                  {{form.supplierName}}
                </span>
              </el-form-item>
            </div>
            <div  class="col col4">
              <el-form-item label="物料编号" >
                <span class="onlyText">
                  {{form.materielCode}}
                </span>
              </el-form-item>
            </div>
            <div  class="col col4">
              <el-form-item label="物料描述">
                <span class="onlyText">
                  {{form.materielName}}
                </span>
              </el-form-item>
            </div>
            <div  class="col col4" >
              <el-form-item label="工厂" >
                  <span class="onlyText">
                  {{form.plantCode+'-'+form.plantName}}
                </span>
              </el-form-item>
            </div>
            <div  class="col col4" >
              <el-form-item label="物料品类" >
                <el-input v-model="form.materielType"></el-input>
              </el-form-item>
            </div>
          </el-form>
          <div class="header" v-if="form.type == 1" >关联凭证</div>
          <el-form  v-if="form.type == 1"  class="content bascform"  :model="associatedDocuments" :class="{disabled:formDisabled}" :disabled="formDisabled"  label-width="120px">
            <div  class="col col4">
              <el-form-item label="采购单编号" >
                  <el-input v-model="associatedDocuments.orderCode"></el-input>
              </el-form-item>
            </div>
            <div  class="col col4">
              <el-form-item label="采购单行号" >
                <span class="onlyText">
                  {{associatedDocuments.orderRow}}
                </span>
              </el-form-item>
            </div>
            <div  class="col col4">
              <el-form-item label="检验批" >
                <span class="onlyText">
                  {{associatedDocuments.inspectionLot}}
                </span>
              </el-form-item>
            </div>
            <div  class="col col4">
              <el-form-item label="物料凭证年度">
                <span class="onlyText">
                  {{associatedDocuments.materialYearDocument}}
                </span>
              </el-form-item>
            </div>
            <div  class="col col4">
              <el-form-item label="物料凭证号" >
                  <span class="onlyText">
                  {{associatedDocuments.materialVoucherNumber}}
                </span>
              </el-form-item>
            </div>
            <div  class="col col4">
              <el-form-item label="物料凭证行号" >
                <el-input v-model="associatedDocuments.materialVoucherRowNumber"></el-input>
              </el-form-item>
            </div>
            <div  class="col col4">
              <el-form-item label="数量" >
                <el-input v-model="form.defectsList[0].badNumber"></el-input>
              </el-form-item>
            </div>
            <div  class="col col4">
              <el-form-item label="备注说明" >
                <el-input v-model="associatedDocuments.remark"></el-input>
              </el-form-item>
            </div>
          </el-form>
          <global-table v-if="form.type == 1" class="bascform"  ref="defectsList" max-height="350" :tableField="tableFieldll" :tableData="defectsList" ></global-table>
          <div class="header" v-if="form.type == 2" >异常描述</div>
          <global-table v-if="form.type == 2" class="bascform"  max-height="350" :tableField="tableFieldsc" :tableData="tableData" ></global-table>
          <div class="action" v-if="form.status == 2">
            <el-button  size="medium" type="primary" @click="querySub">确认</el-button>
            <!-- <el-button size="medium" type="primary" @click="noticeSublier">确认并通知供应商</el-button> -->
          </div>
      </div>
    </div>
    <div v-if="type==='2'?true:false">
      <div class="form">
        <div class="header" >基础信息</div>
        <el-form class="content bascform"  :class="{disabled:formDisabled}"  label-width="140px">
          <div class="col col4">
            <el-form-item label="通知单号：">
              <span>{{form.qualityCode}}</span>
            </el-form-item>
          </div>
          <div  class="col col4">
            <el-form-item label="状态：">
              <span>{{form.status==1?'待采购确认':form.status==2?'待供应商确认':form.status==3?'完成':form.status == 4? '已结案':form.status}}</span>
            </el-form-item>
          </div>
          <div class="col col4">
            <el-form-item label="单据类型：">
              <span>生产质量异常</span>
            </el-form-item>
          </div>
          <div class="col col4">
            <el-form-item label="创建时间：">
              <span>{{form.createDate}}</span>
            </el-form-item>
          </div>
        </el-form>
        <div class="header" >质量对象</div>
        <el-form class="content bascform"  :class="{disabled:formDisabled}"  label-width="140px">
          <div class="col col4">
            <el-form-item label="维修物料编号：">
              <span>{{form.matnr}}</span>
            </el-form-item>
          </div>
          <div class="col col4">
            <el-form-item label="物料描述：">
              <span>{{form.maktx}}</span>
            </el-form-item>
          </div>
          <div class="col col4">
            <el-form-item label="订单发料数量：">
              <span>{{form.menge}}</span>
            </el-form-item>
          </div>
          <div class="col col4">
            <el-form-item label="不良数量：">
              <span>{{form.blqty}}</span>
            </el-form-item>
          </div>
          <div class="col col4">
            <el-form-item label="周结束日期：">
              <span>{{form.zedat}}</span>
            </el-form-item>
          </div>
          <div class="col col4">
            <el-form-item label="周不良率：">
              <span>{{form.blrate}}</span>
            </el-form-item>
          </div>
          <div class="col col4">
            <el-form-item label="供应商名称：">
              <el-input v-model="form.supplierName" placeholder="请输入供应商名称" />
            </el-form-item>
          </div>
          <div class="col col4">
            <el-form-item label="供应商编号：">
              <el-input v-model="form.supplierCode" placeholder="请输入供应商编号" />
            </el-form-item>
          </div>
          <div class="col col4">
            <el-form-item label="备注说明：">{{ form.bz }}</el-form-item>
          </div>
          <div class="col col4">
            <el-form-item label="基本计量单位：">
               <span>{{form.meins}}</span>
            </el-form-item>
          </div>
        </el-form>
        <div class="header">不良品故障明细附件</div>
        <el-form class="content bascform">
          <div class="col col4">
            <el-form-item label="不良品附件明细">
              <el-button type="primary" size="mini" @click="downloadFile" v-if="form.filePath">下载</el-button>
              <span v-else>无</span>
            </el-form-item>
          </div>
        </el-form>
        <el-form class="content bascform">
          <div class="buttondiv">
            <el-button @click="xiugai" type="primary">提交修改</el-button>
          </div>
          <div class="action" v-if="form.status == 2" style="padding-top:15px">
            <el-button  size="medium" type="primary" @click="querySubx">确认提交下一级</el-button>
            <!-- <el-button size="medium" type="primary" @click="noticeSublier">确认并通知供应商</el-button> -->
          </div>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import Component from '@/assets/js/components.js'
import { requestForm, request, baseURL } from '@/assets/js/http.js'
import { tableField, tableData, tableFieldll, tableFieldsc } from './js/setting'
export default {
  components: Component.components,
  name: 'ApplyingDetails',
  data () {
    return {
      tableField: tableField,
      tableFieldll: tableFieldll,
      tableFieldsc: tableFieldsc,
      tableData: tableData,
      defectsList: [],
      // dialogShow: false,
      formDisabled: true,
      form: {},
      associatedDocuments: {}, // 关联凭证
      dialogModify: false,
      type: 1,
      id: ''
    }
  },
  mounted () {
    this.ApplyingDetails()
  },

  methods: {
    xiugai () {
      request('/api/quality/sap/updateQuality', 'post', this.form).then((res) => {
        if (res.code === '200') {
          // console.log(res)
          this.$message({
            message: '成功',
            type: 'success'
          })
        }
      })
    },
    // 确认提交下一级
    querySubx () {
      const obj = {
        ...this.form // 待确认
      }
      obj.status = 3
      console.log('obj...', obj)
      request('/api/quality/sap/updateQuality', 'post', obj).then((res) => {
        if (res.code === '200') {
          // console.log(res)
          this.$message({
            message: '成功',
            type: 'success'
          })
          this.$router.push('/QualitySynergy/Advise')
        }
      })
    },
    ApplyingDetails () {
      this.id = this.$route.query.id
      this.type = this.$route.query.type
      console.log(this.type)
      if (this.type === '1') {
        requestForm('/api/quality/getById/' + this.id, 'post').then((res) => {
          if (res.code === '200') {
            console.log(res)
            this.form = res.data
            this.associatedDocuments = res.data.associatedDocuments
            this.defectsList = res.data.defectsList
          }
        })
      } else {
        requestForm('/api/quality/vo/getById/' + this.id, 'post').then((res) => {
          if (res.code === '200') {
            this.form = res.data
            console.log(this.form)
            console.log(this.form.status)
            // this.associatedDocuments = res.data.associatedDocuments
            // this.defectsList = res.data.defectsList
          }
        })
      }
    },
    // 点击确认
    querySub () {
      const obj = {
        id: this.id,
        status: 3 // 待确认
      }
      console.log('obj...', obj)
      request('/api/quality/update', 'post', obj).then((res) => {
        if (res.code === '200') {
          this.$message({
            type: 'success',
            message: '确认成功'
          })
          this.$router.push('/QualitySynergy/Advise')
        }
      })
    },
    // handleClose () {
    //   this.dialogShow = false
    // },
    // 点击弹框里的确认按钮
    // 确认并通知供应商
    noticeSublier () {
    },
    // 返回
    back: () => {
      window.history.back()
    },
    // 下载文件
    downloadFile () {
      if (this.form.filePath) {
        window.open(baseURL + '/api/supplier/material/download?filePath=' + encodeURIComponent(this.form.filePath) + '&fileName=' + encodeURIComponent(`不良品附件明细-${this.form.qualityCode}.xls`))
      }
    }
  }
}
</script>

<style scoped lang="scss">
  @import '@/assets/css/form.scss';
  @import '@/assets/css/elForm.scss';
 .buttondiv{
    text-align: center;
    float: left;
    width: 100%;
  }
  .zhong{
    float: left;
    width: 45%;
  }
</style>
